export const useRoutingState = () => {
  /**
   * 駐車場詳細ページを閉じた後に戻るページのURL
   * @default HOME
   */
  const redirectUrl = useState<string | undefined>('redirectUrl', () => '/');

  const setRedirectUrl = (_url: string | undefined) => {
    redirectUrl.value = _url;
  };

  return {
    redirectUrl: readonly(redirectUrl),
    setRedirectUrl,
  };
};
